.mainDiv {
}

.search-bar {
  display: flex;
  align-items: center;
  border: 1px solid #888;
  padding: 5px 10px;
  border-radius: 5px;
  width: 200px;
  font-size: 16px;
  margin-right: 10px;
}

.search-icon {
  margin-right: 10px;
  color: #888;
}

.search-bar input {
  border: none;
  outline: none;
  flex: 1;
}

@media only screen and (max-width: 400px) {
  .search-bar {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
