.container {
  background-color: inherit;
  height: 100%;
  font-family: sans-serif;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f6f8fa;
  padding: 25px;
  margin: 0;
}

.body {
  padding: 0 20px 0 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.chartMainDiv {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}
.topDiv {
  display: flex;
  justify-content: space-between;
}

.leftDiv {
}

.newStockButton {
  padding: 7px 13px;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #01687b;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.newStockButtonIcon {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.rightDiv {
  display: flex;
  align-items: center;
}

.chartDiv {
  margin-top: 20px;
  display: grid;
}

@media only screen and (max-width: 576px) {
  .topDiv {
    flex-direction: column;
  }

  .rightDiv {
    justify-content: flex-end;
  }

  .newStockButton {
    margin-bottom: 10px;
    width: fit-content;
  }
}

@media only screen and (max-width: 400px) {
  .rightDiv {
    flex-direction: column;
    align-items: end;
  }
}
