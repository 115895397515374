.flex-container {
  display: flex;
  background-color: #f2f3f9;
}

.left-container {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #fcfcfc;
  height: 100vh;
}

.logo-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100px;
  margin-left: 5px;
  margin-right: 5px;
  width: 300px;
}

.side-nav-bar-container {
  width: 100%;
  max-height: calc(100vh - 102px);
  overflow-y: auto;
}

.right-container {
  background-color: #f5f5f5;
  width: 100%;
}

.top-nav-bar-outer-container {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  z-index: 10;
}

@media screen and (min-width: 1601px) {
  .left-container {
    width: 280px;
  }
  .logo-container {
    width: 280px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .left-container {
    width: 240px;
  }
  .logo-container {
    width: 240px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .left-container {
    width: 210px;
  }
  .logo-container {
    width: 210px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .left-container {
    width: 170px;
  }
  .logo-container {
    width: 170px;
  }
}

@media screen and (max-width: 768px) {
  .left-container {
    display: none;
  }
}
