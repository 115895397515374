.loader {
  width: 20%;
  text-align: center;
  margin: 0 auto 2em;
  display: inline-block;
  vertical-align: top;
  height: auto;
}

/*
    Set the color of the icon
  */

svg rect {
  fill: white;
}
