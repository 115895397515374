.dropdown {
    position: relative;
    width: 100%;
    font-family: "Arial", sans-serif;
    color: #333;
}

.textInput {
    height: 30px;
    border: 1px solid #01687B;
    border-radius: 5px;
    width: 100%;
    padding-left: 4px;
    -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
    -moz-box-sizing: border-box;    /* Firefox, other Gecko */
    box-sizing: border-box;         /* Opera/IE 8+ */
}

.dropdownContent {
    position: absolute;
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    display: none;
    transition: opacity 0.3s ease, transform 0.3s ease;
    transform: translateY(10px);
    opacity: 0;
    box-sizing: border-box;
}

.dropdownContent.show {
    display: block;
    transform: translateY(0);
    opacity: 1;
}

.dropdownItem {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    padding: 10px 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.dropdownItem:hover {
    background-color: #f7f7f7;
}

.dropdownItemCell {
    min-width: 40px;
    padding-left: 2px;
    padding-right: 2px;
}

.noOptions {
    padding: 10px 16px;
    font-size: 14px;
    color: #999;
    text-align: center;
}