.container {
  background-color: inherit;
  height: 100%;
}

.card-container {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 10px;
  padding: 0px 10px 0px 10px;
}

.body {
  padding: 20px;
  margin: 20px 0px 0px 0px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f6f8fa;
  padding: 25px;
  margin: 0;
}

.header h1 {
  margin: 0;
  font-weight: bold;
  font-family: sans-serif;
}

.branch-select-dropdown {
  width: 200px;
}

.date-picker {
  align-self: flex-end;
  background-color: white;
  padding: 0px;
  border-radius: 5px;
  font-weight: 800;
  margin-bottom: 5px;
}

.top-container {
  display: flex;
  flex-direction: row;
}

.top-container-left {
  display: grid;
  grid-template-columns: auto auto;
  gap: 10px;
  padding: 0px 10px 0px 10px;
  flex: 5;
}

.top-container-right {
  display: flex;
  flex-direction: column;
  flex: 4;
}

.bottom-container {
  margin-top: 20px;
}

.bottom-chart-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.chart-container-left {
  flex: 1;
  padding: 0 10px;
}

.chart-container-right {
  flex: 1.5;
  padding: 0 10px;
}

@media all and (max-width: 768px) {
  .header {
    padding: 10px;
    margin: 0;
  }

  .header h1 {
    font-size: 22px;
  }

  .branch-select-dropdown {
    width: 150px;
  }

  .body {
    padding: 0px;
    margin: 20px 0px 0px 0px;
  }

  .date-picker {
    margin-top: 5px;
    margin-right: 5px;
  }

  .top-container {
    display: flex;
    flex-direction: column;
  }

  .top-container-left {
    gap: 5px;
    padding: 0px 5px 0px 5px;
  }

  .top-container-right {
    margin-top: 10px;
    padding: 0px 5px 0px 5px;
  }

  .card-container {
    grid-template-columns: auto auto;
    gap: 5px;
    padding: 0px 5px 0px 5px;
  }

  .bottom-chart-container {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    margin-top: 20px;
  }

  .chart-container-right {
    flex: 1.5;
    padding: 0px 5px 0px 5px;
  }

  .chart-container-left {
    flex: 1;
    padding: 10px 5px 10px 5px;
  }
}
