.side-nav-bar {
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  font-family: "Inter";
  font-size: 14px;
}

.menu-item-container {
  padding: 3px 5px 3px 5px;
}

.menu-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 3px 3px 3px;
  cursor: pointer;
}

.menu-item:hover {
  background-color: #01697b41;
}

.select-menu-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #01687b;
  padding: 3px 3px 3px 3px;
  color: #fcfcfc;
}

.menu-item-container > h3 {
  padding: 10px 10px 10px 10px;
}

span {
  padding: 10px 5px 10px 20px;
}

@media screen and (min-width: 1601px) {
  .side-nav-bar {
    font-size: 14px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .side-nav-bar {
    font-size: 13px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .side-nav-bar {
    font-size: 12px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .side-nav-bar {
    font-size: 10px;
  }
}

@media screen and (max-width: 768px) {
  .side-nav-bar {
    font-size: 8px;
  }
  .mob-spacer {
    height: 30px;
    width: 100%;
  }
}
