.card-medium-body {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 20px 20px;
  border-radius: 10px;
  font-family: "Open Sans";
}

.card-medium-bottom-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-grow: 2;
  padding: 0;
  height: 100%;
  align-items: flex-end;
  align-content: center;
  margin-bottom: auto;
  margin-top: auto;
}

.card-medium-body h1 {
  font-size: 30px;
  color: #343a40;
  padding: 0px;
  margin: 0;
}

.card-medium-body h2 {
  font-size: 23px;
  color: #adb5bd;
  padding: 0px;
  margin: 0;
}

.div-indicator {
  padding: 5px 8px;
  border-radius: 10px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.red {
  background-color: #f9cdce;
  color: #da7072;
  font-size: 20px;
}

.green {
  background-color: #d3f4e5;
  color: #329b57;
  font-size: 20px;
}

.red h1 {
  color: #da7072;
  font-size: 20px;
}

.green h1 {
  color: #329b57;
  font-size: 20px;
}

@media all and (max-width: 768px) {
  .card-medium-body {
    height: 80px;
  }

  .card-medium-body h1 {
    font-size: 25px;
  }

  .card-medium-body h2 {
    font-size: 20px;
  }

  .div-indicator {
    padding: 5px 8px;
    width: 50px;
  }

  .red {
    font-size: 18px;
  }

  .green {
    font-size: 18px;
  }

  .red h1 {
    font-size: 18px;
  }

  .green h1 {
    font-size: 18px;
  }
}

@media all and (max-width: 500px) {
  .card-medium-body {
    height: 45px;
    padding: 8px 8px;
  }

  .card-medium-body h1 {
    font-size: 15px;
  }

  .card-medium-body h2 {
    font-size: 13px;
  }
  .div-indicator {
    padding: 2px 5px;
    width: 35px;
    border-radius: 5px;
  }

  .red {
    font-size: 12px;
  }

  .green {
    font-size: 12px;
  }

  .red h1 {
    font-size: 10px;
  }

  .green h1 {
    font-size: 10px;
  }
}
