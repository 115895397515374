.logo-and-name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.logo-and-name > img {
  width: 50px;
  height: 50px;
  padding-left: 20px;
  padding-right: 10px;
}

.logo-and-name > h2 {
  color: #01687b;
  font-family: Arial;
  font-weight: 800;
  font-size: 24px;
  padding-left: 10px;
}

@media screen and (min-width: 1601px) {
  .side-nav-bar {
    font-size: 14px;
  }

  .logo-and-name > img {
    width: 50px;
    height: 50px;
    padding-left: 20px;
    padding-right: 10px;
  }

  .logo-and-name > h2 {
    font-weight: 800;
    font-size: 24px;
    padding-left: 10px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .side-nav-bar {
    font-size: 13px;
  }

  .logo-and-name > img {
    width: 50px;
    height: 50px;
    padding-left: 20px;
    padding-right: 10px;
  }

  .logo-and-name > h2 {
    font-weight: 800;
    font-size: 24px;
    padding-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .side-nav-bar {
    font-size: 12px;
  }

  .logo-and-name > img {
    width: 50px;
    height: 50px;
    padding-left: 17px;
    padding-right: 7px;
  }

  .logo-and-name > h2 {
    font-weight: 800;
    font-size: 22px;
    padding-left: 7px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .side-nav-bar {
    font-size: 11px;
  }

  .logo-and-name > img {
    width: 40px;
    height: 50px;
    padding-left: 15px;
    padding-right: 5px;
  }

  .logo-and-name > h2 {
    font-weight: 800;
    font-size: 18px;
    padding-left: 5px;
  }
}

@media screen and (max-width: 768px) {
  .side-nav-bar {
    font-size: 10px;
  }
}
