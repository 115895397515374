.innerMenuItemDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.innerMenuItemDiv h3 {
  display: block;
  position: relative;
  align-items: center;
  padding-left: 5px;
}

.mobDevider {
  display: none;
}

.arrow {
  display: flex;
  align-items: center;
  margin-right: 10px;
  font-size: 18px;
}

.subMenuDiv {
  padding: 3px 0px 3px 10px;
}

.subMenuItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 3px 3px 3px 3px;
  cursor: pointer;
}

.subMenuItem:hover {
  background-color: #01697b41;
}

.selectSubMenuItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #01687b;
  padding: 3px 3px 3px 3px;

  color: #fcfcfc;
}
.menuIcon {
  font-size: 16px;
  align-items: center;
  display: flex;
}
