.container {
  margin-bottom: 60vh;
}

.header {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
}
.boxesDiv {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
.box {
  padding: 20px 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.title {
  margin-bottom: 15px;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;
}
.value {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.75);
}
@media (max-width: 1000px) {
  .header {
    font-size: 16px;
  }

  .title {
    font-size: 12px;
  }
  .value {
    font-size: 12px;
  }
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
}
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #09f;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}
.noData {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  font-size: 16px;
  color: #666;
}

.noData .icon {
  font-size: 50px;
  color: #999;
  margin-bottom: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 650px) {
  .box {
    padding: 15px 15px;
  }
  .boxesDiv {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
}
