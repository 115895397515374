* {
  margin: 0;
  padding: 0;
}

.landing-container {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  width: 100vw;
  height: 100vh;
  font-family: Arial, Helvetica;
  letter-spacing: 0.02em;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.progress-bar {
  height: 1px;
  z-index: 1;
}
