.site-footer {
  background-color: #efefef;
  padding: 15px 0 0;
  font-size: 15px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.841);
  font-family: "Roboto", sans-serif;
  margin-top: 20px;
  width: 100%;
  bottom: 0;
}

.site-footer h6 {
  color: #33353d;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 5px;
  font-weight: bold;
  margin-top: 10px;
}

.site-footer a {
  color: #737373;
}

.site-footer a:hover {
  color: #3366cc;
  text-decoration: none;
}

.footer-container {
  max-width: 1200px;
  margin: 0;
  padding: 10px 15px 5px;
}

.footer-row {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.footer-col-6,
.footer-col-3,
.footer-col-8,
.footer-col-4 {
  padding: 0 15px;
}

.footer-col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.footer-col-3 {
  flex: 0 0 25%;
  max-width: 20%;
}

.footer-col-8 {
  flex: 0 0 66.66667%;
  max-width: 66.66667%;
}

.footer-col-4 {
  flex: 0 0 33.33333%;
  max-width: 33.33333%;
}

.text-justify {
  text-align: justify;
}

.footer-links {
  list-style: none;
  padding-left: 0;
}

.footer-links li {
  display: block;
  margin-bottom: 4px;
}

.footer-links a {
  color: #737373;
}

.footer-links a:hover {
  color: #3366cc;
  text-decoration: none;
}

.social-icons {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.footer-links h8 {
  font-size: 16px;
}

.social-icons li {
  display: inline-block;
  margin-bottom: 4px;
  padding: 0px 10px;
}

.social-icons li a i {
  color: #fff;
  font-size: 20px;
  vertical-align: middle;
}

@media (max-width: 767px) {
  .footer-col-6,
  .footer-col-3,
  .footer-col-8,
  .footer-col-4 {
    flex: 0 0 100%;
    max-width: 95%;
  }
}
