.mainDiv {
}

.filterDiv {
  position: relative;
}
.filter {
  display: flex;
  align-items: center;
  border: 1px solid #888;
  font-size: 14px;
  padding: 5px 5px;
  border-radius: 4px;
  cursor: pointer;
}
.filter-icon {
  margin-right: 10px;
}
.filterCloseIcon {
  margin-left: 10px;
}
.noFilterBottomDiv {
  display: none;
}
.filterBottomDiv {
  margin-top: 5px;
  position: absolute;
  right: 0;
  z-index: 100;
  border: 1px solid #888;
  font-size: 14px;
  padding: 5px 10px;
  border-radius: 4px;
  text-align: right;
  width: max-content;
  height: auto;
  background-color: white;
}
.filterBottomItem {
  padding: 5px 5px;
  cursor: pointer;
  border-radius: 4px;
}

.filterBottomItem:hover {
  background-color: #f1f1f1;
}
