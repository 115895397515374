.date-range-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  padding: 5px;
  align-items: center;
  margin: 10px 0;
}

.date-range-container h2 {
  padding: 0 15px;
}

/* #dateRangePickerContainer {
  @import 'rsuite/dist/rsuite.min.css';
} */
