.card-large-body {
  display: block;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  height: 300px;
  font-family: "Open Sans";
  padding: 10px 0;
}

.card-large-body h2 {
  margin: 0;
  padding: 10px 20px 0px 20px;
}

@media all and (max-width: 768px) {
  .card-large-body {
    height: 300px;
  }
}
