.container {
  background-color: inherit;
  height: 100%;
  font-family: sans-serif;
}
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f6f8fa;
  padding: 25px;
  margin: 0;
}

.body {
  padding: 0 20px 0 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.chartMainDiv {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
}
.topDiv {
  display: grid;
  grid-template-columns: auto auto auto auto;
  gap: 20px;
  padding: 0px 10px 0px 10px;
}
.textInputContainer {
  display: flex;
  flex-direction: column;
}
.textInputLabel {
  font-size: 12px;
  font-weight: 600;
}
.textInput {
  height: 25px;
  border: 1px solid #01687B;
  border-radius: 5px;
  padding: 1px 5px 2px 5px;
  margin-top: 5px;
}
.dropdownContainer {
  margin-top: 5px;
}

.addItemButtonContainer {
  display: flex;
  padding: 20px 10px 0px 10px;
}
.addItemButton {
  padding: 7px 20px;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #01687b;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.leftDiv {
}

.newStockButton {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #01687b;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

.newStockButtonIcon {
  margin-right: 5px;
  display: flex;
  align-items: center;
}
.rightDiv {
  display: flex;
  align-items: center;
}

.chartDiv {
  margin-top: 20px;
  display: grid;
}

.buttonPanelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.submitButton {
  padding: 7px 50px;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #01687b;
  color: white;
  font-size: 14px;
  cursor: pointer;
}
.cancelButton {
  padding: 7px 50px;
  border: none;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #D3D3D3;
  color: white;
  font-size: 14px;
  cursor: pointer;
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .topDiv {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    padding: 0px 10px 0px 10px;
  }

  .rightDiv {
    justify-content: flex-end;
  }

  .newStockButton {
    margin-bottom: 10px;
    width: fit-content;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .topDiv {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    padding: 0px 10px 0px 10px;
  }

  .rightDiv {
    justify-content: flex-end;
  }

  .newStockButton {
    margin-bottom: 10px;
    width: fit-content;
  }
}

@media only screen and (max-width: 576px) {
  .topDiv {
    display: grid;
    grid-template-columns: auto;
    gap: 20px;
    padding: 0px 10px 0px 10px;
  }

  .rightDiv {
    flex-direction: column;
    align-items: end;
  }
}
