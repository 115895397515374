/* Title bar */
.productSelectContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.selectedProductContainer {
    display: flex;
    flex-direction: row;
}
.selectedProductNameContainer {
    display: flex;
    flex-direction: column;
    align-items: end;
    margin-right: 10px;
}
.selectedProductIconContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 32px;
}

.productName {
    color: #01687B;
}

/* Form header */
.formSection {
    margin-top: 30px;
}
.sectionTitleContainer {
    display: flex;
    flex-direction: row;
    color: #01687B;
    font-size: 15px;
    margin-top: 5px;
}
.sectionTitle {
    margin-left: 8px;
    margin-bottom: 10px;
}

/* Input form */
.topDiv {
    display: grid;
    grid-template-columns: auto auto auto;
    gap: 20px;
    padding: 0px 10px 0px 10px;
    margin-top: 10px;
}
.bottomDiv {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
    padding: 0px 10px 0px 10px;
    margin-top: 10px;
}
.textInputContainer {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
}

.textInputLabel {
    font-size: 12px;
    font-weight: 600;
}
.textInput {
    height: 25px;
    border: 1px solid #01687B;
    border-radius: 5px;
    padding: 1px 5px 2px 5px;
    margin-top: 5px;
}
.dropdownContainer {
    margin-top: 5px;
}
.discountContainer {
    display: flex;
    flex-direction: row;
}

/* button panel */
.buttonPanel {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
}
.submitButton {
    padding: 7px 50px;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    background-color: #01687b;
    color: white;
    font-size: 14px;
    cursor: pointer;
}
.cancelButton {
    padding: 7px 50px;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    background-color: #D3D3D3;
    color: white;
    font-size: 14px;
    cursor: pointer;
}

@media screen and (min-width: 576px) and (max-width: 768px) {
    .productSelectContainer {
        flex-direction: column;
    }
    .selectedProductContainer {
        margin-top: 10px;
    }
    .topDiv {
        grid-template-columns: auto auto;
    }
    .bottomDiv {
        grid-template-columns: auto;
    }
}

@media screen and (max-width: 576px) {
    .productSelectContainer {
        flex-direction: column;
    }
    .selectedProductContainer {
        margin-top: 10px;
    }
    .topDiv {
        grid-template-columns: auto;
    }
    .bottomDiv {
        grid-template-columns: auto;
    }
}