.card-small-body {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 15px 20px;
  border-radius: 10px;
  font-family: "Open Sans";
}

.card-small-bottom-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-grow: 2;
  padding: 0;
  height: 100%;
  align-items: flex-end;
  align-content: center;
  margin-bottom: auto;
  margin-top: auto;
}

.card-small-body h1 {
  font-size: 25px;
  color: #343a40;
  padding: 0px;
  margin: 0;
}

.card-small-body h2 {
  font-size: 20px;
  color: #adb5bd;
  padding: 0px;
  margin: 0;
}

.small-div-indicator {
  padding: 5px 8px;
  border-radius: 5px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.small-red {
  background-color: #f9cdce;
  color: #da7072;
  font-size: 14px;
  align-items: center;
}

.small-green {
  background-color: #d3f4e5;
  color: #329b57;
  font-size: 14px;
  align-items: center;
}

.small-red h3 {
  color: #da7072;
  font-size: 14px;
}

.small-green h3 {
  color: #329b57;
  font-size: 14px;
}

@media all and (max-width: 800px) {
  .card-small-body {
    height: 80px;
  }

  .card-small-body h1 {
    font-size: 25px;
  }

  .card-small-body h2 {
    font-size: 20px;
  }

  .small-div-indicator {
    padding: 5px 8px;
    width: 50px;
  }

  .small-red {
    font-size: 18px;
  }

  .small-green {
    font-size: 18px;
  }

  .small-red h1 {
    font-size: 18px;
  }

  .small-green h1 {
    font-size: 18px;
  }
}

@media all and (max-width: 500px) {
  .card-small-body {
    height: 45px;
    padding: 8px 8px;
  }

  .card-small-body h1 {
    font-size: 15px;
  }

  .card-small-body h2 {
    font-size: 13px;
  }
  .small-div-indicator {
    padding: 2px 5px;
    width: 35px;
    border-radius: 5px;
  }

  .small-red {
    font-size: 12px;
  }

  .small-green {
    font-size: 12px;
  }

  .small-red h1 {
    font-size: 10px;
  }

  .small-green h1 {
    font-size: 10px;
  }
}
