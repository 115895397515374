.top-nav-bar-container {
  display: flex;
  /* flex-direction: row-reverse; */
  justify-content: flex-end;
  align-items: center;
  height: 60px;
  background-color: #fcfcfc;
  padding: 0 20px;
}
.left-div {
  display: none;
  position: relative;
}
.menu-icon {
  font-size: 24px;
  color: #01687b;
  margin-right: 20px;
}
.logo-div {
  display: flex;
  align-items: center;
  font-family: sans-serif;
  font-size: 16px;
  color: #01687b;
}

.logo-img {
  height: 25px;
  margin-right: 10px;
}

.mob-side-nav-hide {
  position: absolute;
  top: 38px;
  left: -20px;
  background-color: #fcfcfc;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  width: 200px;
  z-index: 1000;
  visibility: hidden;
  transform: translateX(-13em);
  transition: all 0.5s ease 0s, visibility 0s linear 0.5s,
    z-index 0s linear 0.5s;
}

.mob-side-nav {
  position: absolute;
  top: 38px;
  left: -20px;
  background-color: #fcfcfc;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  height: calc(100vh - 40px);
  max-height: calc(100vh - 40px);
  overflow-y: auto;
  width: 200px;
  z-index: 1000;
  visibility: visible;
  transform: translateX(0);
  transition-delay: 0.25s, 0.25s, 0.25s;
}

.right-div {
  display: flex;
  align-items: center;
}
.profile-icon-container {
  color: #01687b;
  margin: 0 10px;
  font-size: 20px;
  cursor: pointer;
}

.notification-icon-container {
  font-size: 20px;
  color: #01687b;
}

@media screen and (min-width: 1601px) {
  .profile-icon-container > img {
    margin-right: 10px;
    margin-left: 10px;
  }

  .notification-icon-container {
    font-size: 20px;
    margin-right: 10px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .profile-icon-container > img {
    margin-right: 9px;
    margin-left: 9px;
  }

  .notification-icon-container {
    font-size: 20px;
    margin-right: 9px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .profile-icon-container > img {
    margin-right: 8px;
    margin-left: 8px;
  }

  .notification-icon-container {
    font-size: 20px;
    margin-right: 8px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .profile-icon-container > img {
    margin-right: 6px;
    margin-left: 6px;
  }

  .notification-icon-container {
    font-size: 20px;
    margin-right: 6px;
  }
}

@media screen and (max-width: 768px) {
  .top-nav-bar-container {
    height: 50px;
    justify-content: space-between;
  }
  .left-div {
    display: flex;
    align-items: center;
  }
  .profile-icon-container > img {
    width: 30px;
    height: 30px;
    margin-right: 5px;
    margin-left: 5px;
  }

  .notification-icon-container {
    font-size: 18px;
    margin-right: 5px;
  }

  .mob-devider {
    height: 2px;
    width: 100%;
    background-color: #ccc;
  }
}
