.login-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px;
  position: relative;
  width: 350px;
  height: 400px;
  top: calc(50% - 180px);
  left: calc(20% - 125px);
  border-radius: 2px;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 15px;
}

.login-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  padding: 10px 30px;
}

.username-field,
.password-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px 5px 10px;
  font-size: 15px;
  color: gray;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  width: 100%;
}

.username-field > input,
.password-field > input {
  width: 250px;
  border: 0px;
  padding: 10px 10px 10px 10px;
  font-weight: 800;
  font-family: "Roboto", sans-serif;
}

input:focus {
  outline: none;
}

.login-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  width: 200px;
  margin-bottom: 50px;
  font-family: "Open Sans";
  border-radius: 20px;
  cursor: pointer;
  font-size: small;
}

.login-header > img {
  width: 60px;
  height: 60px;
  padding-left: 20px;
  padding-right: 10px;
}

.login-header > h2 {
  color: #01687b;
  font-family: Arial;
  font-weight: 800;
  font-size: 24px;
  padding-left: 10px;
}

@media screen and (min-width: 1601px) {
  .login-header > img {
    width: 60px;
    height: 60px;
    padding-left: 20px;
    padding-right: 10px;
  }

  .login-header > h2 {
    font-weight: 800;
    font-size: 24px;
    padding-left: 10px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1600px) {
  .login-header > img {
    width: 60px;
    height: 60px;
    padding-left: 20px;
    padding-right: 10px;
  }

  .login-header > h2 {
    font-weight: 800;
    font-size: 24px;
    padding-left: 10px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .login-header > img {
    width: 60px;
    height: 60px;
    padding-left: 17px;
    padding-right: 7px;
  }

  .login-header > h2 {
    font-weight: 800;
    font-size: 22px;
    padding-left: 7px;
  }
}

@media screen and (min-width: 769px) and (max-width: 1024px) {
  .login-header > img {
    width: 40px;
    height: 50px;
    padding-left: 15px;
    padding-right: 5px;
  }

  .login-header > h2 {
    font-weight: 800;
    font-size: 18px;
    padding-left: 5px;
  }
}

@media screen and (max-width: 768px) {
  .login-card {
    width: 80vw;
    height: 430px;
    left: auto;
    justify-content: center;
    margin: auto;
  }

  .login-form {
    padding: 0px;
  }

  .login-header > img {
    width: 40px;
    height: 50px;
    padding-left: 15px;
    padding-right: 5px;
  }

  .login-header > h2 {
    font-weight: 800;
    font-size: 18px;
    padding-left: 5px;
    margin: 0;
  }

  .username-field,
  .password-field {
    margin-left: 10px;
    margin-right: 10px;
    width: 85%;
  }

  .username-field > input,
  .password-field > input {
    /* width: 100%; */
  }

  .login-button {
    width: 60%;
    font-size: 14px;
    margin-bottom: 0;
  }
}
